import "../styles/footer.css"

const Footer=()=>{
    return(
        <>
             <footer className="footer footer-black  footer-white ">
        <div className="container-fluid">
          <div className="row">
            <div className="credits ml-auto">
              <span className="copyright">
                <b>
               <h5> © made by Amirtha </h5></b>
              </span>
            </div>
          </div>
        </div>
      </footer>
        </>
    )
}
export default Footer